import React from "react"
import { graphql } from "gatsby"


import Layout from "../components/layout"
import ProductList from "./product-list"
// import Sprite from "../components/sprite"


let getProductsByManufacturer = (products,manufacturers) => {
  let ret = {sort:''};

  console.log('IN')
  console.log(products)
  console.log(manufacturers)

  products.edges.map((edge,i)=>{
    const product = edge.node
    const manufacturer = product.frontmatter.manufacturer;
    if(typeof ret[manufacturer]==='undefined')
    {
      ret[manufacturer] = {title:'',id:'',active: false,products:[product]}
      ret.sort+=(ret.sort.length>0?',':'')+manufacturer
    }
    else
      ret[manufacturer].products.push(product);
  });

  manufacturers.edges.map((edge,i)=>{
    const manufacturer = edge.node.fields.id;
    if(typeof ret[manufacturer]!=='undefined'){
      ret[manufacturer].title = edge.node.frontmatter.title;
      ret[manufacturer].active = edge.node.frontmatter.active;
      ret[manufacturer].id = manufacturer;

    }

  });

  return ret
}


export default ({ data, pageContext }) =>   {
  console.log("Page Context");
  console.log(pageContext);
  console.log(data);
  // console.log(getProductsByManufacturer(data.products, data.manufacturers));

  const sorted_data = getProductsByManufacturer(data.products, data.manufacturers)


  return (
    <Layout>
      <h1><span className={`icon small product ${pageContext.catalog}`}/>{pageContext.catalogTitle}</h1>
      <ul className="product-manufacturers">
        {sorted_data.sort.split(',').map((manufacturerID,i)=>{
          const manufacturer = sorted_data[manufacturerID]

          if(manufacturer.active){
            return (
              <li key={`product-manufacturer-${i}`} className="manufacturer">
                <div className="manufacturer">
                  <div className={`icon manufacturer ${manufacturer.id}`}/>
                  <p className="label">{manufacturer.title}</p>
                </div>
                <ul className="products inline-list">
                  {manufacturer.products.map((product,j)=>{
                    console.log('PRODUCT')
                    console.log(product)
                    return(
                      <li key={`manufacturer-${i}-product-${j}`} className="product">
                        <a href={product.fields.slug} className="product-title">{product.frontmatter.title}</a>
                        <p style={{marginTop:0}}><strong><a style={{fontSize:12}}  href={product.fields.slug}>More Info</a></strong></p>
                        {/*<ul className="links-list">*/}
                        {/*  <li className="clearfix"><span className="icon link info black"/</li>*/}
                        {/*  /!*{ product.frontmatter.pdf ?*!/*/}
                        {/*  /!*  <li className="clearfix"><span className="icon link download black"/><a href={product.frontmatter.pdf} target="_blank">Download PDF</a></li>*!/*/}
                        {/*  /!*  : ``}*!/*/}
                        {/*</ul>*/}
                      </li>)
                  })};
                </ul>
              </li>)
          } else return ``

        })}
      </ul>
      <h2>All Products</h2>
      <ProductList/>
    </Layout>
  )
}


/*
TODO:
 * Add PageContext: catalog
 * query products filtered on catalog
 * query manufacturers priority
 * build catalog page in order of Manufacturer priorirt
*/

export const query = graphql`
query($catalog: String!){
  products: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//products//"}, frontmatter: {catalog: {eq: $catalog}}}, sort: {fields: frontmatter___priority, order: ASC}) {
    edges {
      node {
        fields{
          slug
          id
        }
        frontmatter {
          type
          title
          pdf
          manufacturer
        }
      }
    }
  }
  manufacturers: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//manufacturers//"}, frontmatter: {active: {eq: true}}}, sort: {fields: frontmatter___priority}) {
    edges {
      node {
        fields{
          slug
          id
        }
        frontmatter {
          title
          priority
          active
        }
      }
    }
  }
}
`